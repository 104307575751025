import { Inter } from "@next/font/google";

const inter = Inter({
  weight: "variable",
  subsets: ["latin"],
  display: "swap",
});

export default function Layout({ children }: any) {
  return (
    <div className={`${inter.className}`}>
      <main>{children}</main>
    </div>
  );
}
