import React from "react";
import * as Sentry from "@sentry/browser";

export interface ErrorLogger {
  log: (error: unknown, context: string) => void;
}

export class ConsoleErrorLogger implements ErrorLogger {
  log(error: unknown, context: string) {
    // eslint-disable-next-line no-console
    console.error(`[${context}]`, error);
  }
}

export class RethrowingErrorLogger implements ErrorLogger {
  log(error: unknown, _context: string) {
    throw error;
  }
}

export class TestErrorLogger implements ErrorLogger {
  loggedErrors: [unknown, string][] = [];

  log(error: unknown, context: string) {
    if (error instanceof Error) {
      error = error.message;
    }
    this.loggedErrors.push([error, context]);
  }
}

export class SentryErrorLogger implements ErrorLogger {
  log(error: unknown, context: string) {
    Sentry.captureException(error, {
      tags: {
        context: context,
      },
    });
  }
}

export const ErrorLoggerContext = React.createContext(new RethrowingErrorLogger());
