// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

// When we run the server locally with `pnpm run start:production` to test
// performance, we still want Sentry and Google Analytics events to go into
// the development bucket on the server, rather than polluting the production
// events. NEXT_PUBLIC_ANALYTICS_ENV is used to achieve this.
let env = process.env.NEXT_PUBLIC_ANALYTICS_ENV ?? process.env.NODE_ENV;

let didInitSentry = false;
if (env === "production" || process.env.NEXT_PUBLIC_FORCE_SENTRY) {
  Sentry.init({
    dsn: SENTRY_DSN || "https://17edaead887e4d2391337735feaf59f0@o409305.ingest.sentry.io/4504186223788032",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    environment: env,
  });
  didInitSentry = true;
}

if (process.env.NODE_ENV === "production" && env === "development") {
  console.info(
    `%cNext.js environment: production\nAnalytics/Sentry environment: development\nSentry enabled: ${didInitSentry}`,
    "color: #777"
  );
}
