import Script from "next/script";

export default function GoogleAnalytics() {
  const debugMode = (process.env.NEXT_PUBLIC_ANALYTICS_ENV ?? process.env.NODE_ENV) === "development";
  // Using <Script> per https://nextjs.org/docs/messages/next-script-for-ga
  return (
    <>
      {/* Google tag(gtag.js) */}
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-6QQQCT54E0" strategy="afterInteractive" />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-6QQQCT54E0'
        `}
        {debugMode ? ", { debug_mode: true }" : ""}
        {`
          );
        `}
      </Script>
    </>
  );
}
