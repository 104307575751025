import type { AppProps } from "next/app";
import "@/styles/index.css";
import { ErrorBoundary } from "../error-boundary";
import { ErrorLoggerContext, SentryErrorLogger } from "../error-logger";
import Layout from "@/components/layout";
import React from "react";
import Head from "next/head";
import GoogleAnalytics from "@/components/google-analytics";

// Required behavior by MobX
// https://mobx.js.org/installation.html#use-spec-compliant-transpilation-for-class-properties
if (
  !new (class {
    x: any;
  })().hasOwnProperty("x")
) {
  throw new Error("Transpiler is not configured correctly");
}

// Polyfill
if (typeof window !== "undefined") {
  window.Element.prototype.animate ??= (): any => {};
}

// Don't re-instantiate this on each render, as it breaks referential equality.
let errorLogger = new SentryErrorLogger();

// This is a component for the entire app. See
// https://nextjs.org/docs/advanced-features/custom-app
export default function CalculatorApp({ Component, pageProps, ...appProps }: AppProps) {
  let hasLayout = !["/mobilelayout"].includes(appProps.router.pathname);
  let LayoutComponent = hasLayout ? Layout : React.Fragment;
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, viewport-fit=cover, initial-scale=1.0, user-scalable=0" />

        <link rel="manifest" href="manifest.json" />
        <link rel="search" type="application/opensearchdescription+xml" title="calcu.net" href="search.xml" />
        <meta name="application-name" content="Calculator" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Calculator" />
        <meta name="mobile-web-app-capable" content="yes" />
        <link rel="apple-touch-icon" sizes="256x256" href="/favicon-256.png" />

        <meta name="description" content="A free online calculator." />
      </Head>
      <GoogleAnalytics />
      <ErrorLoggerContext.Provider value={errorLogger}>
        <ErrorBoundary>
          <LayoutComponent>
            <Component {...pageProps} />
          </LayoutComponent>
        </ErrorBoundary>
      </ErrorLoggerContext.Provider>
    </>
  );
}
