import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component, ReactNode } from "react";
import { ErrorLoggerContext } from "./error-logger";

interface Props {
  children?: ReactNode;
}

type State = {
  error?: Error;
  hasError: boolean;
};

@observer
export class ErrorBoundary extends Component<Props, State> {
  @observable errorCount = 0;
  containerRef = React.createRef<HTMLDivElement>();

  static contextType = ErrorLoggerContext;
  declare context: React.ContextType<typeof ErrorLoggerContext>;
  get errorLogger(): React.ContextType<typeof ErrorLoggerContext> {
    return this.context;
  }

  static getDerivedStateFromError(e: Error) {
    return { error: e, hasError: true };
  }

  componentDidUpdate(): void {
    if (this.state?.hasError) {
      if (process.env.NODE_ENV === "development") {
        // React handles this for us, so do nothing to avoid infinite loops.
        return;
      }
      this.errorLogger.log(this.state.error, "ErrorBoundary");
      this.errorCount += 1;
      if (this.errorCount > 20) {
        // Prevent infinite errors loops
        window.location.replace(window.location.href.replace(/#.*$/, ""));
      } else {
        this.fadeIn();
        this.setState({ hasError: false });
      }
    }
  }

  fadeIn() {
    this.containerRef.current?.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: 1000,
    });
  }

  render() {
    return <div ref={this.containerRef}>{this.state?.hasError ? "" : this.props.children}</div>;
  }
}
